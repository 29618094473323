import appConfig from '@/app/configs/appConfig.json';
import { NetworkNames, Token } from '@/networks';

/** Defines available transfers statuses. */
export enum TransferStatuses {
    'UNSPECIFIED',
    'CONFIRMING',
    'CANCELED',
    'FINISHED',
    'WAITING',
    'CANCELING',
    'FAILED',
    'ETCHING',
}

export class NetworkAddress {
    constructor(
        public address: string = '',
        public networkName: NetworkNames = NetworkNames.CASPER_TEST,
        public networkId: number = appConfig.numbers.ZERO_NUMBER,
    ) {};
}

export class StringTxHash {
    constructor(public hash: string = '', public networkName: NetworkNames = NetworkNames.SEPOLIA) {}
}

/** Holds transfer domain entity about transferring funds from one network to another. */
export class Transfer {
    constructor(
        public amount: string = '',
        public finalAmount: string = '',
        public createdAt: string = '',
        public id: number = appConfig.numbers.ZERO_NUMBER,
        public outboundTx: StringTxHash = new StringTxHash(),
        public recipient: NetworkAddress = new NetworkAddress(),
        public sender: NetworkAddress = new NetworkAddress(),
        public status: TransferStatuses = TransferStatuses.FINISHED,
        public triggeringTx: StringTxHash = new StringTxHash(),
        public senderToken: Token = new Token(),
        public recipientToken: Token = new Token(),
    ) {}
}

/** Defines request fields to get transfer estimate. */
export class TransferEstimateRequest {
    constructor(
        public senderNetwork: string = '',
        public recipientNetwork: string = '',
        public tokenId: number = appConfig.numbers.ZERO_NUMBER,
        public amount: string = '',
        public senderAddress: string = '',
        public recipientAddress: string = '',
        public senderNetworkId: number = appConfig.numbers.ZERO_NUMBER,
        public recipientNetworkId: number = appConfig.numbers.ZERO_NUMBER,
    ) {}
}

/** Holds approximate information about transfer fee and time. */
export class TransferEstimate {
    constructor(
        public fee: string = '',
        public feePercentage: string = '',
        public estimatedConfirmationTime: string = '',
        public stableFee: string = '',
        public resultAmount: string = '',
        public estimateError: string = '',
        public totalComission: string = '',
        public nativeFee: string = '',
        public nativeStableFee: string = '',
        public totalNativeCommission: string = '',
        public nativeTokenSymbol: string = '',
    ) {}
}

export class TransferPagination {
    constructor(
        public address: string = '',
        public pubKey: string = '',
        public signature: string = '',
        public networkId: number = appConfig.numbers.ONE_NUMBER,
        public offset: number = appConfig.numbers.ZERO_NUMBER,
        public limit: number = appConfig.numbers.FIVE_NUMBER,
    ) {}
}

export class TransfersHistory {
    constructor(
        public limit: number = appConfig.numbers.FIVE_NUMBER,
        public offset: number = appConfig.numbers.ZERO_NUMBER,
        public totalCount: number = appConfig.numbers.ZERO_NUMBER,
        public transfers: Transfer[] = [],
    ) {}
}

/** Holds fields needed to generate signature to cancel transfer. */
export class CancelSignatureRequest {
    constructor(
        public transferId: number = appConfig.numbers.ZERO_NUMBER,
        public signature: string = '',
        public networkId: number = appConfig.numbers.ZERO_NUMBER,
        public publicKey: string = '',
    ) {}
}

/** Holds response fields needed for signature to cancel transfer. */
export class CancelSignatureResponse {
    constructor(
        public status: string = '',
        public nonce: number = appConfig.numbers.ZERO_NUMBER,
        public signature: string = '',
        public token: string = '',
        public recipient: string = '',
        public commission: string = '',
        public amount: string = '',
        public transferId: number = appConfig.numbers.ZERO_NUMBER,
        public deadline: string = '',
    ) {};
};

/** Holds information to request transfer signature. */
export class SignatureRequest {
    constructor(
        public sender: NetworkAddress = new NetworkAddress(),
        public tokenId: number = appConfig.numbers.ONE_NUMBER,
        public amount: string = '',
        public destination: NetworkAddress = new NetworkAddress(),
        public additionalAddresses?: String[]
    ) {}
}

/** BridgeInSignatureResponse holds the values needed to send bridge in transaction. */
export class BridgeInSignatureResponse {
    constructor(
        public token: string = '',
        public amount: string = '',
        public gasCommission: string = '',
        public destination: NetworkAddress = new NetworkAddress(),
        public deadline: string = '',
        public nonce: number = appConfig.numbers.ONE_NUMBER,
        public transferId: number = appConfig.numbers.ONE_NUMBER,
        public signature: string = '',
        public transferType: string = '',
        public estimation: TransferEstimate = new TransferEstimate(),
        public totalCommission: string = '',
    ) {}
}

/** BridgeInParams holds fields needed for calling contract bridge in method .*/
export class BridgeInParams {
    constructor(
        public token: string = '',
        public amount: string = '',
        public gasCommission: string = '',
        public destinationChain: string = '',
        public destinationAddress: string = '',
        public deadline: string = '',
        public nonce: number = appConfig.numbers.ONE_NUMBER,
        public transactionId: number = appConfig.numbers.ONE_NUMBER,
    ) {};
};

/** BridgeInNativeParams holds fields needed for calling contract bridge in coin method .*/
export class BridgeInNativeParams {
    constructor(
        public gasCommission: string = '',
        public destinationChain: string = '',
        public destinationAddress: string = '',
        public deadline: string = '',
        public nonce: number = appConfig.numbers.ONE_NUMBER,
        public transactionId: number = appConfig.numbers.ONE_NUMBER,
    ) {};
};

/** BridgeInCircleParams holds fields needed for calling contract bridge in for circle method .*/
export class BridgeInCircleParams {
    constructor(
        public token: string = '',
        public amount: string = '',
        public gasCommission: string = '',
        public destinationChain: number = appConfig.numbers.ZERO_NUMBER,
        public destinationAddress: string = '',
        public deadline: string = '',
        public nonce: number = appConfig.numbers.ONE_NUMBER,
        public transactionId: number = appConfig.numbers.ONE_NUMBER,
    ) {};
};

/** MultiTokenBurnParams holds fields needed for calling contract multi-token burn method .*/
export class MultiTokenBurnParams {
    constructor(
        public token: string = '',
        public tokenId: string = '',
        public amount: string = '',
        public gasCommission: string = '',
        public destinationChain: string = '',
        public destinationAddress: string = '',
        public deadline: string = '',
        public nonce: number = appConfig.numbers.ONE_NUMBER,
        public transactionId: number = appConfig.numbers.ONE_NUMBER,
    ) {};
};

/** Holds information to verify bridge in transaction data. */
export class VerifyBridgeInRequest {
    constructor(
        public transferId: number = appConfig.numbers.ONE_NUMBER,
        public networkId: number = appConfig.numbers.ONE_NUMBER,
        public txHash: string = '',
        public publicKey: string = '',
        public authenticationSignature: string = '',
    ) {};
};

/** Submit Transaction request for bitcoin network. */
export class SubmitTransactionRequest {
    constructor(
        public transferId: number = appConfig.numbers.ONE_NUMBER,
        public networkId: number = appConfig.numbers.ONE_NUMBER,
        public txData: string = '',
        public publicKey: string = '',
        public authenticationSignature: string = '',
    ) {}
}

/** Submit Transaction response for bitcoin network. */
export class SubmitTransactionResponse {
    constructor(
        public txHash: string = '',
    ) {}
}
