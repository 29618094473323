import {
    DISCONNECT_WALLET,
    SET_CONNECTED_WALLETS,
    SET_WALLET_ACCOUNT,
    Wallet,
    WalletName,
} from '../reducers/wallets';

export const setWallets = (wallets: Wallet[]) => ({
    type: SET_CONNECTED_WALLETS,
    payload: wallets,
});

export const disconnectWallet = (walletName: WalletName) => ({
    type: DISCONNECT_WALLET,
    payload: { walletName },
});

export const setWalletAccount = (
    walletName: WalletName,
    address: string,
    signature?: string
) => ({
    type: SET_WALLET_ACCOUNT,
    payload: { walletName, address, signature },
});
